.timeline {
    background-color: #28333D;
    margin-top: 64px;
    padding-bottom: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 480px;
}

.timeline__headline {
    color: #ffffff;
    font-family: Nunito,sans-serif;
    font-size: 32px;
    margin-top: 56px;
}

.timeline__content {
    margin-top: 32px;
    width: 100%;
    max-width: 840px;
}