.pill {
    background-color: var(--accent-color);
    display: inline-flex;
    height: 54px;
    align-items: center;
    border-radius: 28px;
    transform: translateY(-28px);
    margin-left: 16px;
    transition: 200ms ease box-shadow;
    cursor: pointer;
}

.pill:hover {
    -webkit-box-shadow: 0 0 11px 0 rgba(50, 50, 50, 0.25);
    -moz-box-shadow: 0 0 11px 0 rgba(50, 50, 50, 0.25);
    box-shadow: 0 0 11px 0 rgba(50, 50, 50, 0.25);
}

.pill:first-child {
    margin-left: 0;
}


.pill__icon {
    display: flex;
    height: 32px;
    width: 32px;
    margin-left: 16px;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
}

.pill__icon svg {
    width: 32px;
    height: 32px;
    /*color: #282c34;*/
    color: #ffffff;
}

.pill__text {
    font-family: Nunito, sans-serif;
    font-size: 18px;
    margin-right: 24px;
    color: var(--font-color-lit);
}

@media screen and (max-width: 800px) {
    .pill {
        margin-left: 0;
        margin-top: 24px;
    }

    .pill:first-child {
        margin-top: 0;
    }
}