.timeline-item-connection {
    /*background-color: #3B4C5A;*/
    display: flex;
    justify-content: center;
    position: relative;
}

.timeline-item-connection__circle {
    position: absolute;
    top: -16px;
    background-color: var(--timeline-connection-color);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.timeline-item-connection__circle__inner-circle {
    width: 22px;
    height: 22px;
    background-color: #28333D;
    border-radius: 50%;
}

.timeline-item-connection__bar {
    background-color: var(--timeline-connection-color);
    width: 5px;
    height: 54px;
}