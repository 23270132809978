.gdpr-banner {
    background-color: rgba(255, 255, 255, .4);
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: Nunito, sans-serif;
}

.gdpr-banner__banner-box {
    background-color: #ffffff;
    width: 100%;
    max-width: 640px;
    height: auto;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 24px;
    padding-top: 24px;
    border-radius: 12px;
    margin-left: 16px;
    margin-right: 16px;
    /*-webkit-box-shadow: 0 0 2px 0 rgba(23, 23, 23, 1);*/
    /*-moz-box-shadow: 0 0 2px 0 rgba(23, 23, 23, 1);*/
    box-shadow: 0 0 25px 0 rgba(23, 23, 23, .4);
}

.gdpr-banner__header {
    font-size: 36px;
    color: #282c34;
    display: flex;
    justify-content: center;
}

.gdpr-banner__explaination {
    margin-top: 24px;
    font-size: 20px;
}

.gdpr-banner__actions {
    margin-top: 24px;
    /*background-color: red;*/
    display: flex;
    justify-content: center;
}

.gdpr-banner__actions__button {
    background-color: var(--accent-color);
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 24px;
    cursor: pointer;
    color: #ffffff;
    text-transform: uppercase;
}

.gdpr-banner__actions__button:hover {
    -webkit-box-shadow: 0 0 11px 0 rgba(50, 50, 50, 0.25);
    -moz-box-shadow: 0 0 11px 0 rgba(50, 50, 50, 0.25);
    box-shadow: 0 0 11px 0 rgba(50, 50, 50, 0.25);
    background-color: var(--accent-color-lit);
}