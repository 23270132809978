.skill-indicator {
    margin-top: 24px;
    border-radius: 12px;
    padding-top: 6px;
    padding-bottom: 12px;
    transition: 200ms ease-in background-color;
    margin-left: 16px;
    margin-right: 16px;
}

.skill-indicator--open {
    background-color: rgba(59, 76, 90, .1);
}

.skill-indicator:hover {
    background-color: rgba(59, 76, 90, .1);
    cursor: pointer;
}

.skill-indicator__title {
    display: flex;
    flex-direction: row;
}

.skill-indicator__title__title-text {
    font-family: Nunito, sans-serif;
    font-size: 22px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 24px;
}

.skill-indicator__bar {
    background-color: #3B4C5A;
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    margin-right: 16px;
    height: 24px;
    border-radius: 12px;
    overflow: hidden;
}

.skill-indicator__bar__amount {
    max-width: 100%;
    background-color: #097BFF;
    /*    FF7C00 orange*/
    /*    097BFF blue*/
    /*    FF003A red*/
    /*    00B23B green*/
}

.skill-indicator__bar__amount--red {
    background-color: #FF003A;
}

.skill-indicator__bar__amount--orange {
    background-color: #FF7C00;
}

.skill-indicator__bar__amount--green {
    background-color: #00B23B;
}

.skill-indicator__bar__text {
    align-self: center;
    position: absolute;
    margin-left: 24px;
    font-family: Nunito, sans-serif;
    color: #ffffff;
}

/*more button*/

.skill-indicator__more-info-text {
    font-family: Nunito, sans-serif;
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
}