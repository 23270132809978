.skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 480px;
}

.skills__content__title {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 32px;
    display: flex;
    justify-content: center;

    font-family: Nunito, sans-serif;
    color: #3B4C5A;
    font-size: 32px;
}

.skills__content {
    max-width: 840px;
    width: 100%;
    margin-top: 32px;
}


.skills__content__info-box {
    font-size: 20px;
    font-family: Nunito,sans-serif;
    margin-left: 16px;
    margin-right: 16px;
}

.skills__content__info-box__action {

}
