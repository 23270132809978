/**
 * Default font.
 */
@font-face {
    font-family: Nunito;
    src: url("../../resources/fonts/Nunito-Regular.ttf");
}

/**
 * Highlighting font.
 */
@font-face {
    font-family: "Nunito ExtraBold";
    src: url("../../resources/fonts/Nunito-ExtraBold.ttf") format('ttf');
}