.navigation {
    position: fixed;
    height: 64px;
    background: linear-gradient(90deg, var(--accent-color-RGBA) 0%, var(--accent-color-secondary-RGBA) 100%);
    /*background: linear-gradient(90deg, #FF6713 0%, rgb(226, 45, 0) 100%);*/
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 20;

    -webkit-box-shadow: 0 0 2px 0 rgba(23, 23, 23, 1);
    -moz-box-shadow: 0 0 2px 0 rgba(23, 23, 23, 1);
    box-shadow: 0 0 2px 0 rgba(23, 23, 23, 1);
}

.navigation__mobile-toggle {
    width: 64px;
    display: none;

    /*background-color: red;*/
}

.navigation__mobile-toggle__wrapper {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 300ms ease-in background-color;
}

.navigation__mobile-toggle__wrapper:hover {
    background-color: rgba(255, 255, 255, 0.3);

}

.navigation__mobile-toggle__wrapper svg {
    width: 28px;
    height: 28px;
    color: #ffffff;
}

.navigation__left {
    display: flex;
    align-items: center;
}

.navigation__left__link {
    color: #fff;
    font-family: Nunito, sans-serif;
    text-transform: capitalize;
    margin-left: 16px;
    font-size: 24px;
    cursor: pointer;
}

.navigation__center {
    /*background-color: red;*/
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigation__center__link {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-family: Nunito, sans-serif;
    font-size: 16px;

    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 16px;
    cursor: pointer;
    transition: 300ms ease-in background-color;
}

.navigation__center__link:hover {
    background-color: rgba(255, 255, 255, 0.3);

}

.small-navigation {
    position: fixed;
    z-index: 15;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    padding-top: 64px;
    display: none;
}

.small-navigation__item {
    font-family: Nunito, sans-serif;
    font-size: 22px;
    display: flex;
    justify-content: left;
    padding-left: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    cursor: pointer;
    transition: 200ms ease color, 200ms ease background-color, 200ms ease padding-left;
}

.small-navigation__item:hover {
    background-color: #3B4C5A;
    color: #ffffff;
    padding-left: 24px;
}


@media screen and (max-width: 720px) {
    .navigation__mobile-toggle {
        display: flex;
        width: 64px;
        height: 64px;
        justify-content: center;
        align-items: center;
    }

    .navigation__center .navigation__center__link {
        display: none;
    }

    .small-navigation {
        display: block;
    }
}