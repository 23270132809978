.contact {
    display: flex;
    justify-content: center;
    min-width: 480px;
}

.contact__content {
    flex-grow: 1;
    max-width: 860px;
    display: flex;
    justify-content: space-around;
    margin-left: 16px;
    margin-right: 16px;
}


@media screen and (max-width: 800px){
    .contact__content {
        flex-direction: column;
        justify-content: center;
    }
}