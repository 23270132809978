:root {
    --connection-color: var(--timeline-connection-color);
}

.time-connection {
    /*background-color: red;*/
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    flex-direction: row;
}

.time-connection--align-left {
    justify-content: flex-start;
    padding-left: 56px;
}

.time-connection--align-right {
    justify-content: flex-end;
    padding-right: 56px;
}

.time-connection--align-center {
    justify-content: center;

}

.time-connection__container__text {
    background-color: var(--connection-color);
    font-family: Nunito, sans-serif;
    color: #ffffff;
    font-size: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 26px;
}

.time-connection__container {
    /*background-color: blue;*/
    display: flex;
    flex-direction: column;
    align-items: center;
}


.time-connection__container__bar {
    width: 6px;
    height: 48px;
    background-color: var(--connection-color);
    display: flex;
    position: relative;
    justify-content: center;
}

.time-connection__container__circle-bottom {
    width: 32px;
    height: 32px;
    background-color: var(--connection-color);
    position: absolute;
    z-index: 10;
    bottom: -16px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.time-connection__container__circle-bottom__inner-circle {
    width: 21px;
    height: 21px;
    background-color: #28333D;
    border-radius: 50%;
}


.time-connection__container__circle-top {
    width: 32px;
    height: 32px;

    background-color: var(--connection-color);
    position: absolute;
    z-index: 10;
    top: -16px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.time-connection__container__circle-top__inner-circle {
    width: 21px;
    height: 21px;
    background-color: #28333D;
    border-radius: 50%;
}