.skill-pill {
    background-color: #282c34;
    color: #ffffff;
    font-family: Nunito, sans-serif;
    font-size: 18px;
    border-radius: 24px;
    display: inline-flex;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 12px;
    margin-left: 12px;
}
