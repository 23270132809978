.headline {
    font-family: Nunito, sans-serif;
    font-size: 32px;
    margin-top: 24px;
}

.headline--sub {
    font-family: Nunito, sans-serif;
    font-size: 22px;
    font-weight: bold;
}

.text {
    margin-top: 16px;
    font-family: Nunito, sans-serif;
    font-size: 18px;
}

.text--large {
    font-size: 20px;
}

.text-inline-bold {
    font-weight: bold;
}

.text-inline-link {

}