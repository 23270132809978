.timeline-item {
    background-color: var(--timeline-item-background);
    color: #ffffff;
    font-family: Nunito,sans-serif;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 12px;
    margin-left: 16px;
    margin-right: 16px;
}

.timeline-item__time {
    align-self: center;
    margin-left: 16px;
    margin-top: 24px;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border-radius: 24px;
}

.timeline-item__description {
    font-size: 20px;
    margin-top: 42px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 32px;
}

.timeline-item__time svg {
    height: 16px;
}