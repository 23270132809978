:root {
    --accent-color: #2B85B5;
    --accent-color-RGBA: #2B85B5;
    --accent-color-lit: #42aadd;
    --accent-color-secondary: #0FC18A;
    --accent-color-secondary-RGBA: #0FC18A;
    --font-color-lit: #ffffff;
    --font-color-dark: #3B4C5A;

    --background-color-dark: #233A4C;
    --background-color-lit: #E8E8E8;

    --timeline-connection-color: #009c68;
    --timeline-item-background: rgba(8, 10, 12, .4);
}