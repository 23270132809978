.notification {
    position: fixed;
    background-color: #ffffff;
    z-index: 50;
    bottom: -96px;
    left: 50%;
    transform: translateX(-50%);
    height: 96px;
    width: 100%;
    max-width: 280px;
    margin-left: 16px;
    margin-right: 16px;
    padding-left: 16px;
    padding-right: 16px;

    box-shadow: 0 0 25px 0 rgba(23, 23, 23, .4);

    border-radius: 100px;


    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    transition: 300ms ease-in-out bottom;
}

.notification svg {
    height: 38px;
    width: 38px;
    color: var(--accent-color);
    margin-left: 16px;
    margin-right: 16px;
}

.notification__text {
    font-family: Nunito, sans-serif;
    font-size: 20px;
}

.notification--visible {
    bottom: 28px;
}