
.datenschutz {
    display: flex;
    justify-content: center;
}

.datenschutz__content {
    width: 100%;
    max-width: 840px;
    margin-left: 16px;
    margin-right: 16px;
    background-color: #ffffff;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    margin-top: 24px;
    margin-bottom: 24px;

    border-radius: 12px;
}

@media screen and (max-width: 720px) {
    .datenschutz__content {
        margin: 0;
        border-radius: 0;
    }
}