.profile {
    background-color: #3B4C5A;
    display: flex;
    justify-content: center;
    padding-top: 96px;
    padding-bottom: 96px;
    min-width: 480px;
}

.profile__content {
    width: 100%;
    max-width: 860px;
    min-width: 480px;
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    flex-direction: row;
}

.profile__content__avatar {
    display: flex;
    align-items: center;
}

.profile__content__avatar__img {
    width: 260px;
    height: 260px;
    border-radius: 50%;
}

.profile__content__info-wrapper {
    /*background-color: yellow;*/
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 400px;
    flex-grow: 1;
}

.profile__content__info-wrapper__left-bracket {
    width: 86px;
}

.profile__content__info-wrapper__left-bracket svg {
    color: #ffffff;
    width: 86px;
    z-index: 0;
}

.profile__content__info-wrapper__content {
    flex-grow: 1;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.profile__content__info-wrapper__right-bracket {
    width: 86px;
}

.profile__content__info-wrapper__right-bracket svg {
    width: 86px;
    transform: rotate(180deg);
    color: #ffffff;
    z-index: 0;
}

.profile__content__info-wrapper__content__text {
    font-size: 32px;
    font-family: Nunito, sans-serif;
    color: #ffffff;
}

.profile__content__info-wrapper__content__text--small {
    margin-top: 12px;
    font-size: 26px;
}

.profile__content__info-wrapper__content__text--tiny {
    margin-top: 64px;
    font-size: 22px;
}

@media screen and (max-width: 800px){
    .profile__content{
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
    }

    .profile__content__avatar__img {
        margin-bottom: 64px;
        width: 320px;
        height: 320px;
    }

}