.other-skills {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.other-skills__content {
    /*background-color: #f05e7d;*/
    width: 100%;
    max-width: 840px;
    /*padding-right: 32px;*/
    /*padding-left: 16px;*/
    margin-left: 32px;
    margin-right: 32px;
    padding-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
}

.other-skills__heading-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.other-skills__heading-container__headline {
    margin-left: 16px;
    margin-right: 16px;
    font-family: Nunito,sans-serif;
    font-size: 22px;
    margin-top: 24px;
    margin-bottom: 24px;
}