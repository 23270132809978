.footer {
    background-color: #182027;
    font-family: Nunito, sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 480px;
}

.footer__content {
    width: 100%;
    max-width: 840px;
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 64px;
    margin-bottom: 64px;
}

.footer__content__link {
    color: #ffffff;
    cursor: pointer;
}

.footer__content__link:hover {
    font-weight: bold;
}