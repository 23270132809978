@import "library/fonts/Fonts.css";
@import "library/skinn/Skinn.css";

body {
    margin: 0;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #E8E8E8;
}

#root {
    margin: 0;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #E8E8E8;
}

a {
    font-family: Nunito, sans-serif;
    color: #097BFF;
    font-weight: bold;
    text-decoration: none;
}

a:hover {
    color: #52a2f8;
}

a:visited {
    color: #f0875e;
}

div::selection {
    background-color: #3d4853;
    color: #ffffff
}
img::selection {
    background-color: #3d4853;
    color: #ffffff
}

ul::selection {
    background-color: #3d4853;
    color: #ffffff
}

li::selection {
    background-color: #3d4853;
    color: #ffffff
}