.time-indicator {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.time-indicator__content {
    background-color: var(--timeline-connection-color);
    display: inline-flex;
    align-self: center;
    height: 48px;
    padding-left: 24px;
    padding-right: 24px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 24px;
    color: #ffffff;
    font-family: Nunito,sans-serif;
}